<template>
    <div>
        <navAdmin />
        <div class="container">
            <h3 class="h1 pt-4">
                <span>Packages</span>
                <button v-if="false && $auth.check(admin.id)" @click="create" class="btn btn-primary float-right">Create</button>
            </h3>
            <div v-if="isError" class="alert alert-danger">{{ errorMessage  }}</div>
            <table v-if="!isError && packages.length" class="table mt-3">
                <thead>
                    <tr>
                        <th></th>
                        <th>Title</th>
                        <th>Price</th>
                        <th>Views</th>
                        <th colspan="2">Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in packages" v-bind:key="item.id">
                        <td>
                            <svg v-if="!item.is_disabled" width="2em" height="1em" viewBox="0 0 16 16" class="bi bi-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/>
                            </svg>
                            <svg v-if="item.is_disabled" width="2em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"/>
                                <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"/>
                            </svg>
                        </td>
                        <td>{{ item.title }}</td>
                        <td>${{ item.price }}</td>
                        <td>{{ item.views_amount }}</td>
                        <td>{{ item.description }}</td>
                        <td>
                            <button v-if="false && $auth.check(admin.id)" class="btn btn-danger float-right"  @click="deletePackage(item.id)">Delete</button>
                            <button class="btn btn-primary float-right mr-2" @click="updatePackage(item.id)">Edit</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p class="h4 pt-3" v-if="packages.length == 0">Nothing found!</p>
        </div>
        <div class="container">
            <h3 class="h2 pt-4 mb-3">
                <span>Custom packages</span>
                <button v-if="$auth.check(admin.id)" @click="createCustom" class="btn btn-success float-right">Add</button>
            </h3>

            <b-table
                v-if="custom_packages.length"
                id="special-packages"
                :items="custom_packages"
                :fields="custom_packages_fields"
                :per-page="custom_packages.length"
                :current-page="1"
            >
                <template v-slot:cell(price)="data">
                    ${{ data.item.price }}
                </template>

                <template v-slot:cell(views_amount)="data">
                    {{ data.item.views_amount }}
                </template>

                <template  v-slot:cell(controls)="data">
                        <button style="margin-right: 8px;" v-if="$auth.check(admin.id)" class="btn btn-danger float-right" @click="deletePackage(data.item.id)">Delete</button>
                        <button class="btn btn-primary float-right mr-2" @click="updateSpecial(data.item.id)">Edit</button>
                </template>
            </b-table>
        </div>
        <create ref="createModal" @saved="saved"/>
        <create-special ref="createSpecialModal" @saved="saved" />
        <update ref="updateModal" :id="updateId" @saved="saved"/>
        <update-special ref="updateSpecialModal" @saved="saved" />
        <dropPackage ref="deleteModal" :id="deleteId" @saved="saved" />
    </div>
</template>
<script>
import navAdmin from './../../components/nav-admin';
import create from './Popups/CreatePackage';
import createSpecial from './Popups/CreateSpecialPackage';
import update from './Popups/UpdatePackage';
import updateSpecial from './Popups/UpdateSpecialPackage';
import dropPackage from './Popups/DeletePackage';
import { userType } from './../../mixins/user-type.mixin';

export default {
    mixins: [userType],
    data() {
        return {
            isError: false,
            errorMessage: null,
            packages: [],
            custom_packages: [],
            updateId: null,
            deleteId: null,
            custom_packages_fields: [
                { key: 'price', label: "Price", sortable: false },
                { key: 'views_amount', label: "Views", sortable: true },
                { key: 'controls', label: "", sortable: false }
            ]
        }
    },
    components: { navAdmin, create, update, dropPackage, createSpecial, updateSpecial },
    methods: {
        get() {
            this.$http({ url: 'packages', method: 'GET'})
                .then(this.onGetSuccess, this.onRequestError);
        },
        onGetSuccess(response) {
            this.packages = response.data.data.filter(p => p.type == 0);
            this.custom_packages = response.data.data.filter(p => p.type == 1).sort((a,b) => (a.views_amount > b.views_amount) ? 1 : ((b.views_amount > a.views_amount) ? -1 : 0));
        },
        onRequestError(response) {
            this.isError = true;
            this.errorMessage = response.data;
        },
        create() {
            this.$refs.createModal.show();
        },
        createCustom() {
            this.$refs.createSpecialModal.show();
        },
        updatePackage(id) {
            this.updateId = id;
            this.$refs.updateModal.show();
        },
        updateSpecial(id) {
            this.$refs.updateSpecialModal.id = id;
            this.$refs.updateSpecialModal.show();
        },
        deletePackage(id) {
            this.deleteId = id;
            this.$refs.deleteModal.show();
        },
        saved() {
            this.get();
        }
    },
    mounted() {
        this.get();
    }
}
</script>
<style scoped>
    .container h3 {
        padding-right: 20px;
    }
</style>