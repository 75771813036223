<template>
    <b-modal :id="domId" title="Create Item">
        <b-container fluid>
            <admin-input v-model="price" title="Price" name="price" :errors="fields_errors" />
            <admin-input v-model="discount" title="Discount rate" name="discount" :errors="fields_errors" />
            <admin-input v-model="viewsAmount" title="Views" name="views_amount" :errors="fields_errors" />
            <admin-input v-model="weeksCountMin" title="Delivery time optimistic (weeks)" name="weeks_count_min" :errors="fields_errors" />
            <admin-input v-model="weeksCountMax" title="Delivery time realistic (weeks)" name="weeks_count_max" :errors="fields_errors" />
            <admin-textarea v-model="description" title="Description" name="description" :errors="fields_errors" />
            <admin-textarea v-model="description_ja" title="Description (Ja)" name="description_ja" :errors="fields_errors" />            
        </b-container>    
        <template v-slot:modal-footer="{ cancel }">
            <b-button variant="primary" @click="save">Save</b-button>
            <b-button  @click="cancel()">Cancel</b-button>
        </template>
    </b-modal>  
</template>
<script>
import adminInput from '../../Form/dnd-admin-input';
import adminTextarea from '../../Form/dnd-admin-textarea';
import { randString } from '../../../mixins/rand-string.mixin';

export default {
    data() {
        return {
            domId: "special-package-id",
            fields_errors: [],
            slug: null,
            title: null,
            price: null,
            discount: null,
            viewsAmount: null,
            description: null,
            description_ja: null,
            weeksCountMin: null,
            weeksCountMax: null,
        }
    },
    mixins: [randString],
    components: { adminInput, adminTextarea },
    methods: {
        show() {
            this.$http({ url: 'packages/8', method: 'GET'})
                .then((response) => {
                    this.slug = response.data.data.slug;
                    this.title = response.data.data.title;
                    this.description = response.data.data.description;
                    this.description_ja = response.data.data.description_ja;
                    this.price = response.data.data.price;
                    this.discount = response.data.data.discount;
                    this.viewsAmount = response.data.data.views_amount;
                    this.weeksCountMin = response.data.data.weeks_count_min;
                    this.weeksCountMax = response.data.data.weeks_count_max;
                
                    this.$bvModal.show(this.domId);
                });
        },
        save() {
            this.$http.post('packages', {
                'slug': this.getRandStr(8),
                'title': "Special",
                'price': this.price,
                'discount': this.discount,
                'type': 1,
                'views_amount': this.viewsAmount,
                'description': this.description,
                'description_ja': this.description_ja,
                'weeks_count_min': this.weeksCountMin,
                'weeks_count_max': this.weeksCountMax
            }).then(this.responseSuccess, this.responseError);
        },
        responseSuccess() {
            this.$bvModal.hide(this.domId);
            this.$emit('saved', true);
        },
        responseError(res) {
            this.fields_errors = [];

            if(res.response.data.hasOwnProperty('errors')) {
                this.fields_errors = res.response.data.errors;
            }

            this.$emit('saved', false);
        }
    }
}
</script>
