<template>
    <b-modal id="create-package" title="Create Package">
        <b-container fluid>
            <admin-input v-model="slug" title="Slug" name="slug" :errors="fields_errors" />
            <admin-input v-model="title" title="Title" name="title" :errors="fields_errors" />
            <admin-input v-model="price" title="Price" name="price" :errors="fields_errors" />
            <admin-input v-model="discount" title="Discount rate" name="discount" :errors="fields_errors" />
            <admin-input v-model="viewsAmount" title="Views" name="views_amount" :errors="fields_errors" />
            <admin-textarea v-model="description" title="Description" name="description" :errors="fields_errors" />
            <admin-textarea v-model="description_ja" title="Description (Ja)" name="description_ja" :errors="fields_errors" />            
        </b-container>    
        <template v-slot:modal-footer="{ cancel }">
            <b-button variant="primary" @click="save">Save</b-button>
            <b-button  @click="cancel()">Cancel</b-button>
        </template>
    </b-modal>  
</template>
<script>
import adminInput from '../../Form/dnd-admin-input';
import adminTextarea from '../../Form/dnd-admin-textarea';

export default {
    data() {
        return {
            fields_errors: [],
            slug: null,
            title: null,
            price: null,
            discount: 0,
            viewsAmount: null,
            description: null,
            description_ja: null,
        }
    },
    components: { adminInput, adminTextarea },
    methods: {
        show() {
            this.fields_errors = [];

            this.slug = null;
            this.title = null;
            this.price = null;
            this.discount = 0;
            this.viewsAmount = null;
            this.description = null;
            this.description_ja = null;

            this.$bvModal.show('create-package');
        },
        save() {
            this.$http.post('packages', {
                'slug': this.slug,
                'title': this.title,
                'price': this.price,
                'discount': this.discount,
                'type': 0,
                'views_amount': this.viewsAmount,
                'description': this.description,
                'description_ja': this.description_ja
            }).then(this.responseSuccess, this.responseError);
        },
        responseSuccess() {
            this.$bvModal.hide('create-package');
            this.$emit('saved', true);
        },
        responseError(res) {
            this.fields_errors = [];

            if(res.response.data.hasOwnProperty('errors')) {
                this.fields_errors = res.response.data.errors;
            }

            this.$emit('saved', false);
        }
    },
    mounted() {
        this.item = this.resourceItem;
    }
}
</script>
