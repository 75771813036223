<template>
    <b-modal id="delete-package" title="Delete Package">
        <b-container fluid>
            <div class="alert alert-warning">
                <h3 class="h4">Attention!</h3>
                <p>Your package is going to be deleted. Are you shure?</p>
            </div>
        </b-container>    
        <template v-slot:modal-footer="{ cancel }">
            <b-button variant="danger" @click="drop">Delete</b-button>
            <b-button  @click="cancel()">Cancel</b-button>
        </template>
    </b-modal>  
</template>
<script>
export default {
    data() {
        return {
            packageId: null
        }
    },
    props: {
        id: {
            type: Number,
            default: 0
        }
    },
    watch: {
        id(val) {
            if(val != null) 
            {
                this.$http({ url: 'packages/' + val, method: 'GET'})
                    .then(this.getResponceSuccess, this.onRequestError);
            }
        }
    },
    methods: {
        show() {
            this.$bvModal.show('delete-package');
        },
        drop() {
            if(this.packageId) {
                this.$http.delete('packages/' + this.packageId)
                    .then(this.responseSuccess, this.responseError);
            }
        },
        getResponceSuccess(response) {
            this.packageId = response.data.data.id;
        },
        responseSuccess() {
            this.$bvModal.hide('delete-package');
            this.$emit('saved', true);
        },
        responseError() {
            this.$bvModal.hide('delete-package');
            this.$emit('saved', false);
        }
    },
    mounted() {
        this.item = this.resourceItem;
    }
}
</script>
