<template>
    <b-modal :id="domId" title="Update Item">
        <b-container fluid>
            <admin-input v-model="price" title="Price" name="price" :errors="fields_errors" />
            <admin-input v-model="discount" title="Discount rate" name="discount" :errors="fields_errors" />
            <admin-input v-model="viewsAmount" title="Views" name="views_amount" :errors="fields_errors" />
            <admin-input v-model="weeksCountMin" title="Delivery time optimistic (weeks)" name="weeks_count_min" :errors="fields_errors" />
            <admin-input v-model="weeksCountMax" title="Delivery time realistic (weeks)" name="weeks_count_max" :errors="fields_errors" />
            <admin-textarea v-model="description" title="Description" name="description" :errors="fields_errors" />
            <admin-textarea v-model="description_ja" title="Description (Ja)" name="description_ja" :errors="fields_errors" />
        </b-container>    
        <template v-slot:modal-footer="{ cancel }">
            <b-button variant="primary" @click="save">Save</b-button>
            <b-button  @click="cancel()">Cancel</b-button>
        </template>
    </b-modal>  
</template>
<script>
import adminInput from '../../Form/dnd-admin-input';
import adminTextarea from '../../Form/dnd-admin-textarea';
import { userType } from '../../../mixins/user-type.mixin';

export default {
    mixins: [userType],
    data() {
        return {
            domId: 'update-special-package',
            fields_errors:[],

            slug: null,
            title: null,
            price: null,
            discount: null,
            viewsAmount: null,
            description: null,
            isDisabled: false,
            description_ja: null,
            weeksCountMin: null,
            weeksCountMax: null,
        }
    },
    components: { adminInput, adminTextarea },
    props: {
        id: {
            type: Number,
            default: 0
        }
    },
    watch: {
        id(val) {
            if(val != null) 
            {
                this.fields_errors = [];
                this.$http({ url: 'packages/' + val, method: 'GET'})
                    .then(this.getResponceSuccess, this.onRequestError);
            }
        }
    },
    methods: {
        show() {
            this.$bvModal.show(this.domId);
        },
        save() {
            this.$http.put('packages/' + this.id, {
                'title': "Special " + this.viewsAmount,
                'price': this.price,
                'discount': this.discount,
                'description': this.description,
                'description_ja': this.description_ja,
                'views_amount': this.viewsAmount,
                'is_disabled': this.isDisabled,
                'weeks_count_min': this.weeksCountMin,
                'weeks_count_max': this.weeksCountMax
            }).then(this.responseSuccess, this.responseError);
        },
        getResponceSuccess(response) {
            this.slug = response.data.data.slug;
            this.title = response.data.data.title;
            this.description = response.data.data.description;
            this.description_ja = response.data.data.description_ja;
            this.price = response.data.data.price;
            this.discount = response.data.data.discount;
            this.viewsAmount = response.data.data.views_amount;
            this.weeksCountMin = response.data.data.weeks_count_min;
            this.weeksCountMax = response.data.data.weeks_count_max;
        },
        responseSuccess() {
            this.$bvModal.hide(this.domId);
            this.$emit('saved', true);
        },
        responseError(res) {
            this.fields_errors = [];

            if(res.response.data.hasOwnProperty('errors')) {
                this.fields_errors = res.response.data.errors;
            }
            
            this.$emit('saved', false);
        }
    },
    mounted() {
        this.item = this.resourceItem;
    }
}
</script>
